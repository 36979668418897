import React from 'react'


export default function Services() {
  return (
    <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
      <div className="max-w-4xl text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Fully Automated Verified Forex EA</h1>
        <p className="text-lg text-gray-600 mb-6">
          Experience cutting-edge forex trading automation with our innovative "virtual trades" technology.
          Get started in just 5 easy steps and let our system handle the rest.
        </p>
      </div>

      <div className="max-w-4xl p-6 bg-white shadow-lg rounded-2xl">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">How It Works</h2>
        <ul className="space-y-4 text-gray-600">
          <li className="flex items-center"><span className="text-green-500 font-bold mr-2">✔</span> Opens virtual trades to analyze the market</li>
          <li className="flex items-center"><span className="text-green-500 font-bold mr-2">✔</span> Automatically trades based on perfect entry points</li>
          <li className="flex items-center"><span className="text-green-500 font-bold mr-2">✔</span> Regularly updated for optimal market performance</li>
          <li className="flex items-center"><span className="text-green-500 font-bold mr-2">✔</span> Runs on MT4/MT5 with simple drag-and-drop setup</li>
        </ul>
      </div>

      <div className="max-w-4xl mt-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Get Started in 5 Easy Steps</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {["Register an account", "Login and download the EA", "Copy EA file to specified folder", "Drag/drop Flex EA to your charts in MT4/MT5", "Select your strategy, DONE!"].map((step, index) => (
            <div key={index} className="p-4 bg-white shadow-md rounded-xl flex items-center space-x-3">
              <div className="text-2xl font-bold text-gray-800">{index + 1}.</div>
              <p className="text-gray-600">{step}</p>
            </div>
          ))}
        </div>
      </div>
      <button className="mt-8 px-6 py-3 text-lg font-semibold bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition">Get Started</button>
    </div>
  );
}


import React, { useState } from "react";

const faqs = [
  {
    question: "What is EARNING SQUAD EA?",
    answer:
      "EARNING SQUAD EA is a fully automated forex trading robot that uses virtual trades to determine the perfect entry and exit points for trading. It continuously updates settings for optimal market performance.",
  },
  {
    question: "How do I set up the EA?",
    answer: [
      "Register an account.",
      "Log in and download the EA.",
      "Copy the EA file to the specified folder.",
      "Drag and drop the EA onto your charts in MT4/MT5.",
      "Select your strategy, and the EA will start trading automatically.",
    ],
  },
  {
    question: "Which currency pairs work best with this EA?",
    answer:
      "The EA works best with GBPUSD, USDJPY, USDCAD, USDCHF, EURUSD, AUDUSD, EURJPY, EURCHF, EURGBP, and NZDUSD. However, it can be used on almost any chart.",
  },
  {
    question: "Does the EA have a money management feature?",
    answer:
      "Yes, it automatically adjusts lot sizes based on account balance to optimize trading and risk management.",
  },
  {
    question: "What is the Equity Trailing TP feature?",
    answer:
      "This feature locks in half of the profit and trails it to maximize gains while minimizing risk, ensuring secured profits once TP is reached.",
  },
  {
    question: "Can I customize trading strategies?",
    answer:
      "Yes! The EA comes with highly optimized set files that can be easily loaded to configure different strategies without the need for manual tweaking.",
  },
  {
    question: "Does the EA support hedging?",
    answer:
      "Yes, but it is turned off by default. Some brokers may not allow hedging, so make sure to check with your broker first.",
  },
  {
    question: "How does the news filter work?",
    answer:
      "The EA allows you to avoid trading during high-impact news events by setting buffer times before and after the news release.",
  },
  {
    question: "Is customer support available?",
    answer:
      "Yes, support is directly provided by the developer who codes the EA, ensuring expert assistance with any queries.",
  },
  {
    question: "Can I hide my trading strategy from my broker?",
    answer:
      "Yes, the 'Hide from Broker' feature prevents your broker from tracking your trading activities.",
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-4xl font-bold text-center text-blue-700 mb-10">
        Frequently Asked Questions
      </h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-gray-100 p-5 rounded-lg shadow-md cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-blue-800">
                {faq.question}
              </h2>
              <span className="text-xl">{openIndex === index ? "−" : "+"}</span>
            </div>
            {openIndex === index && (
              <div className="mt-2 text-gray-700">
                {Array.isArray(faq.answer) ? (
                  <ul className="list-disc pl-6">
                    {faq.answer.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{faq.answer}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;

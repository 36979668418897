import React, { useEffect, useRef } from 'react'
import Features from '../components/Features'
import Contents from '../components/Contents'
import Bots from '../components/Bots'
import Services from './Services'
import KeyFeatures from '../components/KeyFeatures'
import Footer from '../components/Footer'
import ForexEA from './About_us'


const Home = () => {


  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      },
      { threshold: 0.3 }
    );

    const element = sectionRef.current;
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);
  return (
    <div>

      {/* Bots */}

      <div>
        <Bots />
      </div>
      


      {/* Features................. */}

      <div >
        <Features />
      </div>
      <div>
      <KeyFeatures/>
      </div>

      {/* Services........................ */}
      <div>
        <div ref={sectionRef} className=' lowToHigh about-section'>
          <Services />
        </div>
      </div>

      <div className=' bg-white'>
        <ForexEA/>
      </div>
      <div>
        <Footer/>
      </div>


    </div>
  )
}

export default Home
import React from 'react'

import MainRouting from './routingPage/MainRouting'


const App = () => {
  return (
    <div>
      <MainRouting/>
    </div>
    
    
  )
}

export default App
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Navbar from '../components/Navbar';
import Home from '../pages/Home'
import FAQ from '../pages/FAQ'
import About_us from '../pages/About_us'
import Orders from '../pages/Orders'
import Services from '../pages/Services'
import Testimonials from '../pages/Testimonials'


const MainRouting = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/aboutUs' element={<About_us />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/orders' element={<Orders />} />
        <Route path='/services' element={<Services />} />
        <Route path='/testimonials' element={<Testimonials />} />
        
      </Routes>
    </BrowserRouter>
  )
}

export default MainRouting
import React from 'react'

const Bots = () => {
  return (
    <div className=' px-4 md:px-8 lg:px-16 pb-8 ' style={{
      backgroundImage: 'url("/bgImg.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: 'auto',

    }}>
      <div className='' >

        <h1 className='mb-5 pt-16 text-3xl text-white font-semibold'>Automated  Trading, Unlimited possibilites</h1>
      </div>
      <div className='grid w-full lg:grid-cols-3 gap-10'>
        <div className=' relative photoAnim border w-full rounded  bg-blue-900 pb-5  cursor-pointer '>
          <h1 className='  flex justify-center text-3xl text-white font-bold mt-5 mb-8'>Bot Father</h1>
          <div>
            <ul className=' space-y-1 text-white   px-10'>
              <li>Minimum Investment:$100 USD</li>
              <li>Maximum Investment:$1000 USD</li>
              <li>It can work on 5 major currencies pair</li>
              <li>Minimum return on Investment:8-10% upto</li>
              <li>Maximum Drawdown:15%</li>
              <li>1 Month Free VPS</li>
              <li>Trade with advance AI features</li>
              <li>Tailored for these seeking a solid foundation with a conservative approach.</li>
              <li>Ideal for risk-adverse investors looking for steady growth.</li>
            </ul>
          </div>
          <div className=' lg:absolute bottom-5 right-5 flex justify-end items-center'>
            <div>
              <p className='text-white font-bold text-3xl' >$99</p> </div>
            <div className='flex m-5'>
              <button className='flex  border px-7 py-2 bg-blue-900 text-white hover:text-black hover:bg-[#ffffff] font-bold rounded-sm'>BUY</button>
            </div>
          </div>
        </div>

        <div className=' relative border w-full rounded  bg-blue-700 pb-5  cursor-pointer '>
          <h1 className='flex justify-center text-3xl text-white font-bold mt-5 mb-8'>Rocketfire</h1>
          <div>
            <ul className=' space-y-1 text-white  px-10'>
              <li>Minimum Investment:$500 USD</li>
              <li>Maximum Investment:$10000 USD</li>
              <li>It can work on 5 major currencies + gold </li>
              <li>Minimum return on Investment:10-45% upto</li>
              <li>Maximum Drawdown:4-8%</li>
              <li>1 Month Free VPS</li>
              <li>Trade with advance AI features</li>
              <li>Designed for clients looking for a more dynamic and diverse investment strategy.</li>
            </ul>
          </div>


          <div className=' lg:absolute bottom-5 right-5 flex pr-5 gap-2 mb-5 justify-end w-full  items-end mt-5'>
            <p className='text-white font-bold text-3xl' >$299</p>

            <button className='flex  border px-7 py-2 hover:bg-[#ffffff] bg-blue-700 hover:text-black text-white font-bold rounded-sm'>BUY</button>


          </div>
        </div>

        <div className=' relative photoAnim2 border w-full rounded  bg-blue-900 pb-5  cursor-pointer '>
          <h1 className='flex justify-center text-3xl text-white font-bold mt-5 '>Tornado EA</h1>
          <h2 className='flex justify-center text-lg text-white font-bold mb-8'>
            (Gold & BTC Scalper)</h2>
          <div>
            <ul className=' space-y-1 text-white  px-10'>
              <li>Minimum Investment:$50 USD</li>
              <li>Maximum Investment: No limit</li>
              <li>It can work on 5 major currencies + gold + idices + btcusd</li>
              <li>Minimum return on Investment depends on lot size (30%-75%)</li>
              <li>Maximum % (depends on lot size)</li>
              <li>1 Month Free VPS</li>
              <li>Trade with advance AI features</li>
              <li>Our premium offering for clients with a larger investment portfolio.</li>
              <li>Comprehensive financial planning services, inculdes tax optimization and estate planning.</li>
            </ul>
          </div>
          <div className='flex pr-5 gap-2 mb-5 justify-end  items-center mt-5'>

            <p className='text-white font-bold text-3xl' >$999</p>

            <button className='flex text-white hover:text-black bg-blue-900 border px-7 py-2 hover:bg-[#ffffff] font-bold rounded-sm'>BUY</button>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Bots


import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaLock } from "react-icons/fa";
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoMdClose } from 'react-icons/io';
import { auth, provider, signInWithPopup } from "../firebaseConfig";

const Navbar = () => {
  const { pathname } = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const getName = localStorage.getItem('userName');
  const photoURL = localStorage.getItem('photoURL');

  // Define navigation links dynamically
  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/aboutUs" },
    { name: "FAQ", path: "/faq" },
    ...(getName ? [{ name: "Order", path: "/orders" }] : []), // Show "Order" only if user is logged in
    { name: "Services", path: "/services" },
    { name: "Testimonials", path: "/testimonials" },
    { name: "Contact Us", path: "https://api.whatsapp.com/send?phone=918770841610", external: true }
  ];

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      localStorage.setItem("userName", user.displayName);
      localStorage.setItem("photoURL", user.photoURL);
      window.location.reload();
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
    localStorage.removeItem("userName");
    window.location.reload();
  };

  return (
    <nav className='sticky top-0 z-50 bg-slate-200 px-4 md:px-8 lg:px-16 shadow-md'>
      <div className='flex justify-between items-center'>
        <Link to={"/"}>
        <img src="/cLogo.png" className='h-16 w-28 md:h-20 md:w-28' alt='Logo' /></Link>

        {/* Desktop Navigation */}
        <ul className='hidden lg:flex space-x-6'>
          {navLinks.map((link, index) => (
            <li key={index}>
              {link.external ? (
                <a href={link.path} target="_blank" rel="noopener noreferrer" className='text-blue-900 font-bold hover:text-blue-700'>
                  {link.name}
                </a>
              ) : (
                <Link to={link.path} className={`font-bold ${pathname === link.path ? 'text-blue-700' : 'text-blue-900'} hover:text-blue-700`}>
                  {link.name}
                </Link>
              )}
            </li>
          ))}
        </ul>

        {/* Member Login */}
        <div className='hidden lg:flex items-center'>
          {getName ? (
            <div className='flex items-center gap-3 bg-blue-900 text-white px-4 py-2 rounded-md cursor-pointer' onClick={handleLogout}>
              <img src={photoURL} className='h-8 w-8 rounded-full' alt='User' />
              <span>{getName}</span>
            </div>
          ) : (
            <button onClick={handleLogin} className='bg-blue-900 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-green-700'>
              <FaLock size={16} /> MEMBER LOGIN
            </button>
          )}
        </div>

        {/* Mobile Menu Button */}
        <button className='lg:hidden' onClick={() => setOpenNav(true)}>
          <RxHamburgerMenu size={30} className='text-black' />
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      {openNav && (
        <div className='fixed inset-0 bg-[#02172e] text-white z-50 flex flex-col p-6 space-y-5'>
          <div className='flex justify-between'>
            <img src='/cLogo.png' className='h-16 w-28' alt='Logo' />
            <button onClick={() => setOpenNav(false)}>
              <IoMdClose size={30} />
            </button>
          </div>
          <ul className='flex flex-col space-y-5'>
            {navLinks.map((link, index) => (
              <li key={index}>
                {link.external ? (
                  <a href={link.path} target="_blank" rel="noopener noreferrer" className='text-lg font-semibold hover:text-gray-400'>
                    {link.name}
                  </a>
                ) : (
                  <Link to={link.path} className='text-lg font-semibold hover:text-gray-400' onClick={() => setOpenNav(false)}>
                    {link.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <div className='mt-6'>
            {getName ? (
              <button onClick={handleLogout} className='w-full bg-red-500 py-3 rounded-md text-white font-semibold'>
                Logout
              </button>
            ) : (
              <button onClick={handleLogin} className='w-full bg-green-500 py-3 rounded-md text-white font-semibold'>
                🔒 MEMBER LOGIN
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from 'react'

export default function Footer() {
    return (
      <footer className="bg-slate-300 text-white py-8">
        <div className="container mx-auto px-6 flex flex-col items-center">
          {/* Brand Title */}
          <img height={70} width={200} src="/cLogo.png" alt="" />
  
          {/* Links Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6 w-full max-w-2xl">
            <a target="_blank" href="https://www.earningsquad.com" className="flex items-center gap-2 bg-blue-900 px-4 py-2 rounded-lg hover:bg-blue-600 transition">
              🌐 Website
            </a>
            <a target="_blank" href="mailto:info@earningsquad.com" className="flex items-center gap-2 bg-blue-900 px-4 py-2 rounded-lg hover:bg-blue-600 transition">
              ✉️ Email
            </a>
            <a target="_blank" href="https://t.me/earning_squad555" className="flex items-center gap-2 bg-blue-900 px-4 py-2 rounded-lg hover:bg-blue-600 transition">
              📢 Telegram
            </a>
            <a target="_blank" href="https://instagram.com/earning_squad555" className="flex items-center gap-2 bg-blue-900 px-4 py-2 rounded-lg hover:bg-blue-600 transition">
              📸 Instagram
            </a>
          </div>
  
          {/* Copyright */}
          <p className="text-sm mt-6 text-gray-600">
            © 2025 Earning Squad. All rights reserved.
          </p>
        </div>
      </footer>
    );
  }
  
  
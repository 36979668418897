import React, { useState, useEffect } from "react";

const testimonials = [
  {
    name: "John Doe",
    review:
      "EARNING SQUAD EA has completely transformed my trading experience! The automated strategies and precision entries have significantly improved my profits.",
    location: "New York, USA",
  },
  {
    name: "Sarah Williams",
    review:
      "I love the flexibility of this EA. The money management and news filters work flawlessly. Highly recommended for both beginners and pros!",
    location: "London, UK",
  },
  {
    name: "Michael Lee",
    review:
      "A game-changer in forex trading. The support is top-notch, and the EA's performance is outstanding. My best investment so far!",
    location: "Sydney, Australia",
  },
  {
    name: "Sophia Garcia",
    review:
      "Finally, an EA that delivers results! The community support and constant updates make it the best choice for traders.",
    location: "Toronto, Canada",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Auto-slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="max-w-3xl mx-auto text-center py-12">
      <h2 className="text-3xl font-bold text-blue-700 mb-6">What Our Clients Say</h2>
      <div className="relative bg-gray-100 p-6 rounded-lg shadow-md">
        <p className="text-lg text-gray-800 italic">"{testimonials[currentIndex].review}"</p>
        <h3 className="mt-4 font-semibold text-blue-900">
          - {testimonials[currentIndex].name}, {testimonials[currentIndex].location}
        </h3>

        {/* Navigation Buttons */}
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
          <button
            onClick={prevSlide}
            className="text-gray-600 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
          >
            ◀
          </button>
        </div>
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
          <button
            onClick={nextSlide}
            className="text-gray-600 bg-white p-2 rounded-full shadow-md hover:bg-gray-200"
          >
            ▶
          </button>
        </div>
      </div>

      {/* Dots Indicator */}
      <div className="flex justify-center mt-4">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`h-3 w-3 mx-1 rounded-full ${
              index === currentIndex ? "bg-blue-700" : "bg-gray-400"
            }`}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;

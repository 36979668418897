import React from "react";

const KeyFeatures = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-gray-50 shadow-xl rounded-2xl">
      <h1 className="text-4xl font-bold text-center mb-8 text-blue-600">EARNING SQUAD EA - Key Features</h1>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-blue-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Money Management</h2>
          <p className="text-gray-600">Adjusts lot sizes based on account balance automatically, even as it grows.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-green-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">News Filter</h2>
          <p className="text-gray-600">Avoid high impact news events by setting your preferred buffer time before and after.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-red-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Equity Trailing TP</h2>
          <p className="text-gray-600">Lock in half the profit and trail for higher potential gains while minimizing risk.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-yellow-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">DD Stop Loss</h2>
          <p className="text-gray-600">Set a percentage-based fail-safe to close all trades at a defined drawdown limit.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-purple-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Session Filters</h2>
          <p className="text-gray-600">Control when to trade by enabling or disabling sessions like Asian, New York, or London.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-indigo-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Indicators</h2>
          <p className="text-gray-600">Use EMA crosses, RSI, Bollinger Bands, and many more configurable indicators.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-teal-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Time Filters</h2>
          <p className="text-gray-600">Avoid certain hours or weekdays based on market conditions.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-pink-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Hedging</h2>
          <p className="text-gray-600">Enable hedging strategies if supported by your broker.</p>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md border-l-4 border-gray-500">
          <h2 className="text-xl font-semibold mb-2 text-gray-800">Hide from Broker</h2>
          <p className="text-gray-600">Ensure your broker can't track your trading strategy.</p>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;

import React, { useEffect, useRef } from 'react'

const features1 = [
    "Auto lot on balance",
    "Auto lots on ref capital",
    "% of risk",
    "USE DIFFERENT STRATEGY PER CHANNEL",
    "DIFFERENT CHANNELS IN DIFFERENT MT 4/MT5",
    "CUSTUM TP/SL",
    "Metals signals",  
  ];

  const features2=[
    "Open up to TP 5",
    "Command SL to be",
    "Combine the different TP levels",
    "Open incomplete signals",
    "Name of channels in comments",
    "Hide name of channel in comments",
    "Exclude different pairs (example gold oil etc)",
    "Custom break even after tot n pips",
  ]

  const features3=[
    "Each channel can have dedicated money management and strategy!",
    "Trailing stop loss",
    "Confirmation mode",
    "Filter days/trades/max number of trades",
    "Filter by hours",
    "Automatic SL to entry after TP1",
  ]

  const features4 =[
     "Trade only a terminate pair or pairs…..",
    "Close Partial (can select in setting half close or the preferred % to close from 10% to 90%)",
    "Insert all signals as market execution/signals provider price (pending)",
    "Execution in 0.300 milliseconds from signal receive!",
    "EDIT TP COMMAND",
  ]

  const features5 =[
    "Custom GOLD LOTS",
    "Indices signals",
    "Custom lot/strategy for each pair",
    "Forex signals",
    "TRACK BACK EDITED MESSAGES",
    "Deactivated close command",
    "Exclude pairs from trading",
  ]

const Features = () => {
  const sectionRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        },
        { threshold: 0.1 }
      );
  
      const element = sectionRef.current;
      if (element) observer.observe(element);
  
      return () => {
        if (element) observer.unobserve(element);
      };
    }, []);
  return (
    <div ref={sectionRef} className=' lowToHigh about-section'>
      <h1 className='flex justify-center m-10 text-4xl font-bold '> Bot Features</h1>
      <div class="bg-blue-900 py-5">
      <div class="mx-auto grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12 px-4 py-10 md:max-w-screen-xl md:px-8">
      <div>
            <h1 class=" flex justify-center items-center text-4xl mt-20 italic font-medium text-white">
              Manual lots:
            </h1>
          </div>

          <div class="border-white/40 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-blue-900 px-4 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
            {features1.map((column, colIndex) => (
           <ul key={colIndex} className="space-y-1 text-white list-disc pl-5 ">
              <li key={colIndex * 4} className=" pb-1  break-words">
                {column}
               </li>
            </ul>
          ))}
          </div>


          <div class="border-white/40 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-blue-900 px-4 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
            
            {features2.map((column, colIndex) => (
           <ul key={colIndex} className="space-y-1 text-white list-disc pl-5 ">
              <li key={colIndex * 4} className=" pb-1  break-words">
                {column}
               </li>
            </ul>
          ))}
          </div>


          <div class="border-white/40 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-blue-900 px-4 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
           
            {features3.map((column, colIndex) => (
           <ul key={colIndex} className="space-y-1  ">
              <li key={colIndex * 4} className=" pb-1  break-words text-white list-disc ml-5">
                {column}
               </li>
            </ul>
          ))}
          </div>
          
          <div class="border-white/40 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-blue-900 px-4 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
            
            {features4.map((column, colIndex) => (
           <ul key={colIndex} className="space-y-1  list-disc text-white ml-5 ">
              <li key={colIndex * 4} className=" pb-1  break-words">
                {column}
               </li>
            </ul>
          ))}
          </div>
          <div class="border-white/40 relative border-4 px-4 pt-14 pb-8">
            <div class="absolute -top-8 left-8 bg-blue-900 px-4 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
            
            {features5.map((column, colIndex) => (
           <ul key={colIndex} className="space-y-1  ">
              <li key={colIndex * 4} className=" pb-1  break-words text-white ml-5 list-disc">
                {column}
               </li>
            </ul>
          ))}
          </div>
        </div>
        
      <h2 className="text-3xl font-bold text-center mt-5 mb-6 text-white">Extra Functions</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:px-8 px-4">
        {["Auto Spread TP/SL", "10-50 PIPS to TP/SL", "Stop Profit Target Reached", "Set Profit Target to Reach", "Close all positions if max daily loss reached", "Max Daily Loss", "Overall Loss Limit", "Max Daily Profit Target", "Close single trade if reaching a % of DD", "Close all opposite positions on new signal", "Choose delay for signal execution"].map((feature, index) => (
          <div key={index} className="p-4 bg-white rounded-lg shadow-md border-l-4 border-gray-500">
            <p className="text-gray-700 font-medium">{feature}</p>
          </div>
        ))}
      </div>
      </div>
    </div>

  )
}

export default Features
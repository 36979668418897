import React from "react";

const ForexEA = () => {
  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-2xl">
      <h1 className="text-3xl font-bold text-center mb-6">Simplicity</h1>
      <p className="text-gray-700 mb-6 text-center">
        EA is a forex robot ready to go with out-of-the-box settings—simply plug
        and play. Just attach it to a chart, select the strategy from a
        drop-down list, and click OK. One of the easiest forex robots on the
        market.
      </p>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Supported Currency Pairs</h2>
          <p className="text-gray-700">
            Works best on (GBPUSD, USDJPY, USDCAD, USDCHF, EURUSD, AUDUSD, EURJPY,
            EURCHF, EURGBP, NZDUSD) but can be traded on virtually any chart.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Optimized Set Files</h2>
          <p className="text-gray-700">
            Our highly optimized set files take the built-in strategies a step
            further. Simply download a .set file from your members area, load it
            into Flex, and you're ready to trade.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Community</h2>
          <p className="text-gray-700">
            The only EA on the market with an unfiltered community forum where
            traders can share, discuss, and brainstorm together.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Customer Support</h2>
          <p className="text-gray-700">
            Direct support from the developer. Get insights and help from the person
            who built the EA.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Precision</h2>
          <p className="text-gray-700">
            New market monitoring technology ensures precise entry points. You can
            adjust virtual trades for accuracy or frequency.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Flexibility</h2>
          <p className="text-gray-700">
            This forex robot can be set up to trade any strategy with just a few
            clicks, offering unparalleled flexibility.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForexEA;
